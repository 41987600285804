import {
  LinkedinFilled,
  MessageOutlined,
  PlusOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { ConfigProvider, Divider, Skeleton, Space, Tag, theme } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  addUserToFavorites,
  removeUserFromFavorites,
} from "api/resources/users";
import BoothButton from "components/BoothButton";
import Swipe from "components/Swipe";
import { Button } from "components/antd";
import {
  Avatar,
  Card,
  CardHead,
  Link,
  Typography,
  TypographySizes,
} from "components/antd";
import { MutualInterestsOutlined } from "components/icons";
import {
  formatUserCompanyNameWithJobTitle,
  formatUserFullName,
  formatUserInitials,
  isLoaded,
} from "helpers";
import { twoHundredCharacterDescriptionMaxWidth } from "helpers/constants";
import { useRefElementProperties } from "hooks";
import {
  useBreakpoint,
  useDispatch,
  useIsUserViewer,
  useMatchingInterests,
  usePath,
  useSelector,
} from "hooks";
import type { InterestTag } from "hooks";
import { ProtectedPaths } from "routes";
import type { ProfileProps } from "store";
import { fetchEvent } from "store/appSlice";
import {
  isMeetingsCreationAllowed,
  selectAccountId,
  selectEventProfileIsSingleEventAccessAndOnboardingNotFinished,
  selectIsUserFavourite,
  selectMeetingRequestsReceivedCount,
} from "store/selectors";
import { boxShadowCard, styled } from "styles";
import type { StyledProps } from "styles";
import { colorGrayButtonBackground, colorGraySemi } from "styles/variables";

const StyledButtonsContainer = styled.div(
  ({ styledProps: { gap } }: StyledProps) => ({
    display: "flex",
    flexWrap: "wrap",
    gap,
  })
);

const StyledCard = styled(Card)({
  boxShadow: `${boxShadowCard} !important`,
  ".ant-card-actions": {
    borderTop: 0,
    "> li": {
      margin: 0,
      "> span": {
        cursor: "inherit",
        "&:hover": {
          color: "inherit",
        },
      },
    },
  },
});

const StyledCardMeta = styled(Card.Meta)({
  ".ant-card-meta-detail": {
    "> div:not(:last-child)": {
      marginBottom: 0,
      whiteSpace: "normal",
    },
  },
});

const roleColors: any = {
  "Project Lead": "#f50",
  Jury: "#108ee9",
};

type ProfileCardProps = {
  isLoading?: boolean;
  onBoothButtonClick: () => void;
  onProposeMeetingButtonClick?: (user: ProfileProps) => void;
  user: ProfileProps;
};

const ProfileCard = ({
  isLoading = false,
  onBoothButtonClick,
  onProposeMeetingButtonClick,
  user,
}: ProfileCardProps) => {
  const { isDesktop } = useBreakpoint();
  const dispatch = useDispatch();
  const isUserViewer = useIsUserViewer(user);
  const { eventId } = useParams();
  const navigate = useNavigate();
  const eventChatPath = usePath(ProtectedPaths.EventChat);
  const {
    ref,
    relativeToViewport: { width },
  } = useRefElementProperties();
  const accountId = useSelector(selectAccountId);
  const isSingleEventAccessAndOnboardingNotFinished = useSelector(
    selectEventProfileIsSingleEventAccessAndOnboardingNotFinished
  );
  const { token } = theme.useToken();
  const isUserFavourite = useSelector((state) =>
    selectIsUserFavourite(state, user.id)
  );
  const [isFavourite, setIsFavourite] = useState(isUserFavourite);

  const handleProposeMeetingButtonClick = useCallback(() => {
    onProposeMeetingButtonClick(user);
  }, [onProposeMeetingButtonClick, user]);

  const handleStartChatButtonClick = useCallback(() => {
    navigate(eventChatPath + "/" + user.id);
  }, [user, navigate, eventChatPath]);

  const isMeetingsCreationAllowedSelector = useSelector(
    isMeetingsCreationAllowed
  );

  const handleAddFavoriteClick = async () => {
    try {
      const data = {
        userId: user.id,
      };

      await addUserToFavorites(accountId, eventId, data);
      setIsFavourite(!isFavourite);
      dispatch(fetchEvent(accountId, eventId));
    } catch (err) {
      throw err;
    }
  };

  const handleRemoveFavoriteClick = async () => {
    try {
      await removeUserFromFavorites(accountId, eventId, user.id);
      setIsFavourite(!isFavourite);
      dispatch(fetchEvent(accountId, eventId));
    } catch (err) {
      throw err;
    }
  };

  const {
    isMatchingInterestsLoaded,
    matchingInterestsCount,
    userBusinessTagsWithMatchingInterests,
  } = useMatchingInterests(user);

  const interestTagsElement = useMemo(() => {
    const noInterestsText = isUserViewer
      ? "You have not selected any interests"
      : "You have no matching interests";

    const placeholderElement = isMatchingInterestsLoaded ? (
      <Typography.Paragraph type="secondary">
        {noInterestsText}
      </Typography.Paragraph>
    ) : (
      <Space>
        <Skeleton.Button shape="round" />
        <Skeleton.Button shape="round" />
        <Skeleton.Button shape="round" />
      </Space>
    );

    return [
      <Swipe
        fontSize={TypographySizes.XS}
        padding={token.paddingLG}
        style={{ marginBottom: token.marginLG }}
      >
        {(userBusinessTagsWithMatchingInterests.length > 0 &&
          userBusinessTagsWithMatchingInterests.map(
            ({ id, label }: InterestTag) => <span key={id}>{label}</span>
          )) ||
          placeholderElement}
      </Swipe>,
    ];
  }, [
    isMatchingInterestsLoaded,
    isUserViewer,
    userBusinessTagsWithMatchingInterests,
    token,
  ]);

  const chatButtonPlaceholderElement = <div />;
  const fontSize = token.sizeLG;
  const isEllipsisExpandable = true;
  const normalizeEllipsisOffsetCausedByAntdBug = isEllipsisExpandable ? 1 : 0;
  const linkedinPlaceholderElement = (
    <LinkedinFilled style={{ fontSize, visibility: "hidden" }} />
  );

  return (
    <StyledCard
      actions={!isLoading && interestTagsElement}
      bordered={false}
      headStyle={{
        backgroundColor: token.colorPrimary,
        color: token.colorBgLayout,
      }}
      loading={isLoading}
      ref={ref}
      style={{ width: "100%" }}
      type="inner"
    >
      {user?.companyId && (
        <CardHead justifyContent="flex-end">
          <BoothButton onClick={onBoothButtonClick} />
        </CardHead>
      )}
      {user?.role && (
        <CardHead justifyContent="flex-end">
          <Tag color={roleColors[user.role] || "default"}>{user.role}</Tag>
        </CardHead>
      )}
      <Space direction="vertical" size="large">
        <StyledCardMeta
          avatar={
            <Avatar
              initials={formatUserInitials(user)}
              size={82}
              src={user.avatarUrl}
            />
          }
          description={
            isLoaded(user) ? (
              <Typography.Text
                color={colorGraySemi}
                size={TypographySizes.SM}
                strong
              >
                {formatUserCompanyNameWithJobTitle(user)}
              </Typography.Text>
            ) : (
              <Skeleton.Input active={true} size="small" />
            )
          }
          style={{ alignItems: "center", paddingTop: 15 }}
          title={
            isLoaded(user) ? (
              formatUserFullName(user)
            ) : (
              <Skeleton.Input active={true} size="small" />
            )
          }
        />
        <Typography.Paragraph
          color={colorGraySemi}
          ellipsis={{
            expandable: isEllipsisExpandable,
            rows:
              (width > twoHundredCharacterDescriptionMaxWidth ? 3 : 4) +
              normalizeEllipsisOffsetCausedByAntdBug,
            symbol: "more",
          }}
          isEllipsisRowsVisible
          size={TypographySizes.SM}
        >
          {user.description}
        </Typography.Paragraph>
        <StyledButtonsContainer styledProps={{ gap: token.sizeSM }}>
          {!isUserViewer && isMeetingsCreationAllowedSelector && (
            <Button
              block={!isDesktop}
              disabled={isSingleEventAccessAndOnboardingNotFinished}
              icon={<PlusOutlined />}
              onClick={handleProposeMeetingButtonClick}
              shape="round"
              size="large"
              type="primary"
            >
              Propose a meeting
            </Button>
          )}
          <Space
            size="middle"
            style={{
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            {!isUserViewer ? (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: colorGrayButtonBackground,
                    colorPrimaryHover: colorGrayButtonBackground,
                    colorTextLightSolid: token.colorText,
                  },
                }}
              >
                <Button
                  disabled={isSingleEventAccessAndOnboardingNotFinished}
                  icon={<MessageOutlined />}
                  onClick={handleStartChatButtonClick}
                  shape="round"
                  size="large"
                  type="primary"
                >
                  Chat
                </Button>
              </ConfigProvider>
            ) : (
              chatButtonPlaceholderElement
            )}
            <Space size="large">
              {user.linkedIn ? (
                <Link to={user.linkedIn}>
                  <LinkedinFilled style={{ fontSize }} />
                </Link>
              ) : (
                linkedinPlaceholderElement
              )}
              {!isUserViewer && (
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  size="large"
                  onClick={
                    isFavourite
                      ? handleRemoveFavoriteClick
                      : handleAddFavoriteClick
                  }
                >
                  {isFavourite ? (
                    <StarFilled style={{ fontSize, color: "#FADB14" }} />
                  ) : (
                    <StarOutlined style={{ fontSize }} />
                  )}
                </Button>
              )}
            </Space>
          </Space>
        </StyledButtonsContainer>
        {!isUserViewer && (
          <>
            <Divider style={{ margin: 0 }} />
            <Space>
              <Typography.Text
                color={colorGraySemi}
                size={TypographySizes.SM}
                strong
                style={{ marginBottom: -token.paddingXXS }}
              >
                {matchingInterestsCount > 0
                  ? `${matchingInterestsCount} mutual interests`
                  : "No mutual interests"}
              </Typography.Text>
              {matchingInterestsCount > 0 && (
                <MutualInterestsOutlined fill={token.colorPrimary} />
              )}
            </Space>
          </>
        )}
      </Space>
    </StyledCard>
  );
};

export default ProfileCard;
