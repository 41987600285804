import { MoreOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Skeleton, Space, theme } from "antd";
import type { MenuProps } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Typography, TypographySizes } from "components/antd";
import { formatDatesRange, isLoaded } from "helpers";
import { useDispatch, useNavigateToPath, useSelector } from "hooks";
import { ProtectedPaths } from "routes";
import type { EventProps } from "store";
import { fetchAllEvents, fetchJoinedEvents, unsetEvents } from "store/appSlice";
import {
  selectAccountId,
  selectAllEvents,
  selectEvent,
  selectJoinedEvents,
} from "store/selectors";
import { styled } from "styles";
import { colorGrayMenu, fontSizeHeading4 } from "styles/variables";

import MenuItem from "./MenuItem";

const StyledDropdownButton = styled(Dropdown.Button)({
  ".ant-btn": {
    height: "initial",
    textWrap: "wrap",
    "&:first-child": {
      paddingBottom: 0,
      paddingLeft: 0,
      paddingTop: 0,
      textAlign: "left",
      width: "100%",
    },
    "&:last-child": {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    },
  },
});

const StyledWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

type EventSwitcherProps = {
  className: "string";
};

const EventSwitcher = ({ className }: EventSwitcherProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const accountId = useSelector(selectAccountId);
  const allEvents = useSelector(selectAllEvents);
  const event = useSelector(selectEvent);
  const joinedEvents = useSelector(selectJoinedEvents);
  const navigateToEvents = useNavigateToPath(ProtectedPaths.Events);
  const { token } = theme.useToken();

  useEffect(
    function fetchOnMountAndUnsetOnUnmount() {
      dispatch(fetchAllEvents());
      dispatch(fetchJoinedEvents(accountId));

      return () => {
        dispatch(unsetEvents());
      };
    },
    [accountId, dispatch]
  );

  const isNotificationsBadgeVisible = joinedEvents?.some(
    ({ notificationsCount }: EventProps) => notificationsCount > 0
  );

  const joinedEventsDropdownMenuItems: MenuProps["items"] =
    joinedEvents
      ?.filter((joinedEvent: EventProps) => joinedEvent.path !== eventId)
      .map(
        ({
          isOnboardingStarted,
          isOnboardingFinished,
          notificationsCount,
          path,
          title,
        }: EventProps) => ({
          label: (
            <MenuItem
              isOnboardingUnfinished={
                isOnboardingStarted && !isOnboardingFinished
              }
              notificationsCount={notificationsCount}
              title={title}
            />
          ),
          key: path,
          onClick: () => navigate(`/${path}`),
        })
      ) || [];

  const dividerDropdownMenuItem: MenuProps["items"] =
    joinedEventsDropdownMenuItems.length > 0
      ? [
          {
            type: "divider",
          },
        ]
      : [];

  const dropdownMenuItems: MenuProps["items"] = [
    ...joinedEventsDropdownMenuItems,
    ...dividerDropdownMenuItem,
    {
      label: <MenuItem title="View all events" />,
      key: "all",
      onClick: navigateToEvents,
    },
  ];

  return (
    <div className={className}>
      {isLoaded(allEvents) && isLoaded(event) ? (
        <StyledWrapper>
          <StyledDropdownButton
            icon={
              isNotificationsBadgeVisible ? (
                <Badge dot>
                  <MoreOutlined style={{ fontSize: fontSizeHeading4 }} />
                </Badge>
              ) : (
                <MoreOutlined style={{ fontSize: fontSizeHeading4 }} />
              )
            }
            menu={{ items: dropdownMenuItems }}
            placement="bottom"
            size="large"
            type="link"
          >
            {event.logo ? (
              <img
                style={{ maxWidth: "146px", height: "auto" }}
                src={event.logo}
                alt={event.title}
              />
            ) : (
              <Typography.Title level={4} style={{ marginBottom: 0 }}>
                {event.title}
              </Typography.Title>
            )}
          </StyledDropdownButton>
          <Typography.Text
            color={colorGrayMenu}
            size={TypographySizes.SM}
            strong
          >
            {formatDatesRange(event.startDate, event.endDate)}
          </Typography.Text>
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            {event.title}
          </Typography.Title>
        </StyledWrapper>
      ) : (
        <Space>
          <Skeleton.Input />
          <Skeleton.Avatar
            shape="square"
            style={{ borderRadius: token.borderRadiusSM }}
          />
        </Space>
      )}
    </div>
  );
};

export default EventSwitcher;
