import {
  Action,
  AnyAction,
  MiddlewareArray,
  ThunkAction,
  configureStore,
} from "@reduxjs/toolkit";
import type { Timestamp } from "firebase/firestore";
import {
  FirebaseReducer,
  actionTypes as firebaseActionTypes,
  firebaseReducer,
} from "react-redux-firebase";
import {
  FirestoreReducer,
  actionTypes as firestoreActionTypes,
  firestoreReducer,
} from "redux-firestore";
import logger from "redux-logger";

import { MeetingTypes } from "helpers/enums";

import appReducer, {
  fetchAccountInitialReducer,
  fetchAccountLoadingReducer,
  fetchAccountSuccessReducer,
  unsetMeetings,
  updateMeetings,
} from "./appSlice";

export type { Store } from "@reduxjs/toolkit";
export type Dispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type Thunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export enum FirestoreReducerKeys {
  InterestTags = "interestTags",
  Matches = "matches",
  Meetings = "meetings",
  ReceivedMeetings = "receivedMeetings",
  SentMeetings = "sentMeetings",
}

type FetchingProps = {
  isEmpty: boolean;
  isLoaded: boolean;
  isTerminating?: boolean;
};

type AccountProps = {
  createdAt: Timestamp;
  email: string;
  firstName: string;
  lastName: string;
};

export type AppProps = {
  account: FetchingProps & AccountProps;
  event: FetchingProps & EventProps;
  events: EventsProps;
  meetings: MeetingProps[];
  companies: CompanyProps[];
  error: string;
  firebaseToken: string;
};

export type CompanyProps = {
  id: string;
  name: string;
  image: string;
  additionalInfo: string;
  exhibitorUrl: string;
  externalId: string;
  products: CompanyProductProps[];
};

export type CompanyProductProps = {
  id: string;
  name: string;
  image: string;
  description: string;
  externalId: string;
  url: string;
};

export type EventProps = {
  chat: EventChatProps;
  company: FetchingProps & CompanyProps;
  coverImageSrc: string;
  endDate: Date;
  favouritedUsers: string[];
  id: string;
  interests: FetchingProps & EventInterestsProps;
  isInPersonMeetingAvailable: boolean;
  isInPersonMeetingWithDedicatedTables: boolean;
  isLocked: boolean;
  isOnboardingStarted: boolean;
  isOnboardingFinished: boolean;
  meetingTimes: {
    [MeetingTypes.InPerson]: TimeProps[];
    [MeetingTypes.Virtual]: TimeProps[];
  };
  notificationsCount: number;
  path: string;
  profile: FetchingProps & ProfileProps;
  startDate: Date;
  ticketUrl: string;
  title: string;
  users: ProfileProps[];
  logo: string;
  parentId: string;
  inPersonMeetingDurationMinutes: number;
  inPersonMeetingEndDate: Date;
  inPersonMeetingStartDate: Date;
  inPersonMeetingStartTime: string;
  inPersonMeetingEndTime: string;
  meetingsRestrictionEnabled: boolean;
  meetingsRestrictedEndDate: Date;
  meetingsRestrictedStartDate: Date;
  meetingsOpenWithAdminApprovalEndDate: Date;
  meetingsOpenWithAdminApprovalStartDate: Date;
  meetingsOpenWithoutApprovalEndDate: Date;
  meetingsOpenWithoutApprovalStartDate: Date;
};

type EventChatProps = {
  target: {
    profile: ProfileProps;
  };
};

export type EventInterestsProps = { items: string[] };

export type EventInterestTagProps = {
  id: string;
  name: string;
};

type EventsProps = {
  all: EventProps[];
  joined: EventProps[];
};

export type ProfileProps = {
  avatarUrl?: string;
  companyId?: string;
  companyName?: string;
  countryCode?: string;
  description?: string;
  email?: string;
  exhibitorUrl?: string;
  firstName?: string;
  id?: string;
  interests?: ProfileInterestsProps;
  isOnboardingFinished?: boolean;
  isSingleEventAccess?: boolean;
  jobTitle?: string;
  lastName?: string;
  linkedIn?: string;
  phoneNumber?: string;
  role?: string;
};

export type ProfileInterestsProps = {
  business: string[];
  network: string[];
};

export type MeetingProps = {
  cancelled_message: string;
  id: string;
  inviter_id: string;
  invitee_id: string;
  inviter: string;
  invitee: string;
  proposed_time: any;
  message: string;
  created_at: string;
  status: string;
  archived: {
    [key: string]: boolean;
  };
  decline_message: string;
  declined_at: string;
  meetingLink: string;
  tableNumber: number;
  type: MeetingTypes;
  is_needed_admin_approve?: string;
  is_admin_approved?: string;
};

export type TimeProps = {
  hour: number;
  minute: number;
};

export type TimeWithAvailabilityProps = TimeProps & {
  isAvailable: boolean;
};

type FirestoreProps = {
  [FirestoreReducerKeys.InterestTags]: FirestoreReducer.Entity<EventInterestTagProps>;
  [FirestoreReducerKeys.Matches]: FirestoreReducer.Entity<ProfileProps>;
  [FirestoreReducerKeys.Meetings]: FirestoreReducer.Entity<MeetingProps>;
  [FirestoreReducerKeys.ReceivedMeetings]: FirestoreReducer.Entity<MeetingProps>;
  [FirestoreReducerKeys.SentMeetings]: FirestoreReducer.Entity<MeetingProps>;
};

type StoreProps = {
  app: AppProps;
  firebase: FirebaseReducer.Reducer<ProfileProps>;
  firestore: FirestoreReducer.Reducer<FirestoreProps>;
};

const clearListenerState = (action: AnyAction) => ({
  type: firestoreActionTypes.LISTENER_RESPONSE,
  meta: action.meta,
  payload: { data: {}, ordered: [] as object[] },
  merge: { docs: true, collections: true },
});

const observeActions = (store: any) => (next: any) => (action: any) => {
  const isReceivedMeetings =
    action.meta?.storeAs === FirestoreReducerKeys.ReceivedMeetings;
  const isSentMeetings =
    action.meta?.storeAs === FirestoreReducerKeys.SentMeetings;

  switch (action.type) {
    case firebaseActionTypes.LOGIN: {
      next(fetchAccountLoadingReducer(action));
      return next(action);
    }

    case firebaseActionTypes.LOGOUT: {
      next(fetchAccountInitialReducer(action));
      return next(action);
    }

    case firebaseActionTypes.SET_PROFILE: {
      next(fetchAccountSuccessReducer(action));
      return next(action);
    }

    case firestoreActionTypes.LISTENER_RESPONSE: {
      const state = store.getState();
      const onlyViewerUserProfileIsReturned =
        action.payload.ordered.length === 1 &&
        action.payload.data[state.firebase.auth.uid];

      switch (true) {
        case onlyViewerUserProfileIsReturned:
          // Bug is present when setting userProfile and useFirestoreForProfile in rrfConfig
          // Redux dispatches preliminary listener response with user profile as a payload when listening for changes in Firebase 'users' collection
          // Similar bug reported in here: https://github.com/prescottprue/react-redux-firebase/issues/1034
          return next(clearListenerState(action));

        case isReceivedMeetings || isSentMeetings:
          const meetings = action.payload.ordered;
          return next(updateMeetings(meetings));

        default:
          return next(action);
      }
    }

    case firestoreActionTypes.DOCUMENT_ADDED:
    case firestoreActionTypes.DOCUMENT_MODIFIED: {
      switch (true) {
        case isReceivedMeetings || isSentMeetings:
          const meetings = [{ id: action.meta.doc, ...action.payload.data }];
          return next(updateMeetings(meetings));

        default:
          return next(action);
      }
    }

    case firestoreActionTypes.UNSET_LISTENER: {
      switch (true) {
        case isReceivedMeetings || isSentMeetings:
          return next(unsetMeetings());

        default:
          next(clearListenerState(action));
          return next(action);
      }
    }

    default:
      return next(action);
  }
};

const isDevelopmentMode = process.env.NODE_ENV === "development";
const logging = true; // TODO: Replace with environment variable

const customMiddleware =
  isDevelopmentMode && logging ? [observeActions, logger] : [observeActions];

export const store = configureStore<StoreProps, any, MiddlewareArray<any>>({
  reducer: {
    app: appReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(customMiddleware),
});
